.ViewIndividualGovUser {
  .row {
    --cui-gutter-y: 1rem !important;
  }

  .suspense-loader {
    min-height: 40vh;
  }

  .IndividualGovUser {
    .go-back:hover {
      background-color: transparent;
      border-color: transparent;
    }

    .info-row-card {
      min-height: 100%;

      .category {
        p {
          font-weight: bolder;
          font-size: 18px;
          margin-right: 1rem;
        }
      }
    }

    .gov-user-header {
      svg {
        height: 50px;
        width: 50px;
        fill: $primary-accent;
      }
      .gov-user-avatar {
        width: 60px;
        height: 60px;
        .Avatar {
          width: 100%;
          height: 100%;
        }
        .InitialedAvatar {
          display: flex;
          width: 60px;
          height: 60px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
        }
      }
      .icon-container {
        width: 22px;
        height: 22px;
      }
    }

    .gov-user-card-container {
      width: 100%;
    }

    .card-header {
      background-color: white !important;
    }

    .gov-user-column {
      .gov-user {
        min-height: none;
        .loe-badge {
          width: 25px;
          height: 25px;
        }
      }

      .loe-card {
        min-height: auto;
      }
    }
  }

  .error-container {
    min-height: 50vh;

    .gov-user.error {
      margin-top: -300px;
      width: 500px;
      height: 200px;
    }
  }
}
