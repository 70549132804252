.ServerBackedDropdown {
  align-content: center;
  display: grid;

  .required-icon {
    position: relative;
    left: -17px;
    top: 15px;
    color: #00A0DF;
    font-size: 8px;
    margin-left: 5px;
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-right: 15px;
  }
  .dropdown {
    border: 1px solid #b1b7c1;
    border-radius: 0.25rem;

    .dropdown-input {
      align-items: baseline;
      padding: 7px 15px;
      background-color: #FFF;
      color: #FFF;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;

      p {
        margin: 0;
        color: #FFF;
      }

      i {
        margin-right: 10px;
        color: #636f83;
      }

      &:disabled, &.disabled {
        opacity: 0.75;
        cursor: not-allowed;

        i {
          cursor: not-allowed;
        }
      }


      justify-content: center;
      display: flex;
      flex-direction: row;

      @include flex-row-justify-space-between;

      .dropdown-label {
        color: $secondary-font2;
      }

      .icon-container {
        @include flex-column;
      }

      .subtle {
        font-size: 1rem !important;
        color: $secondary-font2;
      }
    }

    .dropdown-menu {
      width: 100%;
      max-height: 200px;
      overflow-y: scroll;
      overscroll-behavior-y: contain;

      &.active {
        display: block;
      }

      .dropdown-item {   
        .label-description-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &.selected {
          p {
            color: $primary-accent;
          }
        }

        &:active {
          p {
            color: #FFF !important;
          }
        }


        &.select-none {
          color: $primary-accent;
          font-style: italic;
          
          &:active {
            color: #FFF !important;
          }
        }
    }
      


      .dropdown-item-loader {
        @include flex-row-justify-center;

        .dropdown-item-spinner {
          height: 25px;
        }
      }

    }
  }
}
