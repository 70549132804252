.IgniteSearch {
  margin: 20px 0;

  .toggleable-filter-card {
    padding: 20px;
  }

  .simple-search-container {
    .SearchInputGroup {
      width: 60%;
    }

    .flex-row {
      width: 100%;

      &.buttons-row {
        padding-left: unset;
        margin: 0 0 10px 0;

        .form-switch-container {
          display: block;
          align-items: center;
        }
      }

      &.label {
        margin-right: 48px;
      }

      .CustomDropdown {
        width: 35%;
        margin-bottom: 0px;

        .dropdown-value {
          padding: 2px 10px;
          border: 1px solid $grey-stroke-darker;
        }
      }

      .btn-primary {
        height: 38px;
        align-items: center;
      }
    }
  }

  .filter-row {
    .form-control {
      width: 38%;
    }
  }

  .advanced-search-toggle {
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }
}
