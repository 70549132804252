.connect-listing-use-cases-tab {

  .create-use-case-button:hover {
    background-color: var(--cui-light);
    color: $primary-accent;
  }

  .create-use-case-button:focus {
    background-color: var(--cui-light);
    color: $primary-accent;
  }

  .connect-listing-use-cases-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;

    h1 {
      width: fit-content;
      margin-left: 1rem;

      font-size: 20px !important;
      font-weight: bold !important;
      margin: 0;
      color: $secondary-font2;
    }

    .btn {
      height: 50px;
      margin-right: 2rem;
    }
  }

  .row.listing-use-cases {
    justify-content: center;
    min-height: 200px;
    width: 100%;
    margin-left: 0.75rem;

    .no-use-cases {
      max-width: 300px;
      height: 100px;
      text-align: center;
    }
  }

  .pagination-container {
    margin-top: 20px;
  }

  .item-col {
    min-width: 400px;
  }
}

