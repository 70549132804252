@import '../../mixins/flex.scss';
@import '../../mixins//buttons.scss';

.OrganizationDetails {
  .poc-dropdown {
    .btn-group {
      width: 100%;

      .dropdown-toggle {
        @include flex-row-between-center;
        @include span-btn;
      }
    }
  }

  .sm-input-group {
    min-height: 63px;

    .input-group {
      flex-wrap: unset !important;
    }

    span.input-group-text {
      max-height: 38px;
      max-width: 38px;
    }
  }

  .card-body {
    padding-right: 25px;
  }
}
