.ExploreListings {
  min-width: 700px;

  .fa-info-circle {
    color: $primary-accent;
    margin: 0 0 0.5rem 10px;
    line-height: 1.2;
  }

  .rich-tooltip {
    width: 300px;
    z-index: 1000;
  }

  &.Ignite {
    .error-card {
      margin-top: 100px;
      padding: 20px;

      .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .listings-grid {
      .filter-card {
        padding: 0px;
        margin: 0px;
        border: none;

        .listings-filters {
          padding: unset;
        }
      }
    }

    .listings-filters {
      .filter-row {
        .tech-type-filter {
          margin: 0 20px;
        }
      }
    }
  }

  .ServerBackedAutoComplete {
    .autoselect {
      padding: 7px 35px 7px 7px !important;
    }

    .clear-button {
      padding: 6px 15px !important;
    }
  }

  .listings-rank {
    color: #002e6d;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-right: 10px;
  }

  .left-arrow {
    transform: rotate(180deg);
    height: 25px !important;
    width: 25px !important;

    svg {
      path {
        fill: $primary !important;
      }
    }
  }

  .listings-favorite-container {
    float: right;
    color: $secondary-font1;
  }

  .listings-favorite-container > * {
    padding-left: 5px;
  }

  .IgniteListingDetailCard,
  .ListingDetailCard {
    a {
      color: $primary;
      text-decoration: none;
    }
  }

  .hot-listings-content {
    width: 100%;
    margin-left: 10px;

    h5 {
      font-size: 2em;
      font-weight: bold;
      color: $primary-font;
    }

    span {
      font-size: 16px;
      color: $primary-font;
    }

    .show-listings-link {
      padding-top: 10px;
    }

    .hot-listings-favorites {
      padding-top: 10px;
      float: right;

      svg {
        float: right;

        path {
          fill: $primary;
        }
      }
    }
  }

  .bordered {
    border-bottom: 1px solid $grey-stroke;
  }

  .listings-grid {
    display: flex;
    flex-direction: row;
    width: 1000;

    .filter-card {
      padding: 10px;

      .CustomSelectedItems {
        margin: 5px 0 0 0;
      }
    }

    h2 {
      margin-left: 20px;
    }

    .listings-filters {
      width: 100%;
      margin-top: 0px;
      align-self: flex-start;

      .filter-row-last {
        margin: 0px 20px;
        display: grid;
        grid-template-columns: [first] 25% [second] 25% [third] 50% [fourth] 0%;

        .reset-button-container {
          display: flex;
          justify-content: flex-end;
          align-items: baseline;

          .CustomButton {
            padding: 7px 17px !important;
            margin-top: 5px;
            min-width: 110px;
            width: 20%;
            background-color: #fff !important;

            &.reset {
              @include flex-row-center-center;
              justify-content: flex-end;
              min-width: 70px;
              padding: 0 !important;

              p,
              i {
                color: $primary-accent;
              }
            }
          }
        }

        .sort-by {
          margin-right: 10px;
        }

        .favorited-filter {
          margin-right: 10px;
        }
      }

      .filter-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        &.multi-select {
          margin-top: 20px;
        }

        .tech-type-filter {
          // margin: 0 20px;
          margin: 0 20px 0 0;
        }

        .tech-type-filter,
        .naics-code-filter {
          width: 50%;

          span {
            font-style: normal;
          }
        }

        .organization-filter {
          width: 50%;
        }
      }

      .CustomDropdown {
        position: relative;

        .dropdown-menu {
          width: 100%;
        }
      }

      .dropdown-value {
        height: 33px;
        border: 1px solid $grey-stroke-darker;
        padding-left: 15px;
      }
    }

    .listings-pagination {
      width: 100%;
      margin-bottom: 10px;
    }

    .listings-card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 100px;

      .no-data-placeholder {
        @include flex-row-justify-center;
        align-items: center;
      }

      .listings-card {
        width: 23.5%;
        margin: 0px 10px 20px 10px;
        min-width: 317.22px;

        .card {
          padding: 30px;
          min-height: 400px;
          overflow: hidden;

          h3 {
            color: $secondary-font1;
          }

          .show-listings-header {
            height: 30px;
          }

          .listings-brief {
            padding-top: 10px;
            height: 7.8em;
            position: relative;
            overflow: hidden;
          }

          .listings-brief:after {
            content: '';
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
            height: 1.2em;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 1) 50%
            );
          }

          .listings-icons {
            float: left;

            .event-icon {
              height: 27px;
              width: 27px;
            }

            .pdf-icon {
              height: 26px;
              width: 26px;
            }

            .video-icon {
              height: 30px;
              width: 30px;
            }

            svg {
              path {
                fill: $primary-font;
              }
            }
          }

          .listings-icons > * {
            margin: 0px 5px;
          }

          .show-listings-footer {
            display: flex;
            justify-content: space-between;
            margin-top: auto;

            span {
              font-size: 16px;
            }
          }
        }
      }
    }

    .listings-grid-left {
      min-width: 100% !important;

      .filter-card {
        margin: 20px 0;
      }

      .header-container {
        h1 {
          margin-right: 5px;
        }

        .CustomInformationPopup {
          justify-content: center;
          align-items: center;
        }

        &.listings-header {
          justify-content: space-between;
        }

        .header-left {
          align-items: center;
        }

        .CustomButton {
          margin: unset;
        }
      }
    }
  }

  .listings-filters {
    padding: 10px;

    .search-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 15px 0;

      .CustomButton {
        display: flex !important;
        padding: 7px 17px !important;
      }

      .SearchInput {
        width: 100%;
        margin-right: 1%;
      }
    }

    .search-button-container {
      width: 116.41px;
      margin-bottom: 10px;
    }
  }

  &.Ignite {
    .listings-filters {
      .SearchInput {
        width: 100%;
        margin-right: 0%;

        input {
          border: 1px solid #d9d9d9 !important;
        }
      }
    }

    .CustomButton {
      margin: 0 0 0 1.5%;
    }
  }
}
