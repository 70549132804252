.ContractingDetails {
  .dropdown {
    .btn-group {
      width: 100%;

      .dropdown-toggle {
        @include flex-row-between-center;
        @include span-btn;
      }
    }
  }

  textarea.form-control {
    min-height: calc(7em + (0.75rem + 2px));
  }
}