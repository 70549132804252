.ProfileDetailCard {
  .card-header {
    background: white;
  }

  .card-image-container {
    @include flex-row-justify-center;
    .card-img-top {
      align-self: center;
      max-height: 200px;
      object-fit: contain;
    }
    .user-avatar {
      width: 200px;
      height: 200px;
      border-radius: 0%;
      &.initials {
        min-height: 200px;
        min-width: 200px;
        //border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $tertiary-font;
        color: $white;
        overflow: hidden;
        font-size: 5rem;
        border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125))
      }
    }
  }

  .card-subtitle {
    @include flex-row;
    margin-bottom: 15px;
  }

  .card-footer {
    background: white;
    padding: 1rem;
    .col {
      @include flex-row;
    }
  }
  .icon-wrapper {
    min-width: 30px;

    i {
      margin: 0
    }
  }
  .tech-type-badges {
    width: 32%;
    margin-right: 1%;
  }

  .action-row {
    container-type: inline-size;
    button {
      width: 49%;
      margin-right: 1%;
      margin-bottom: 1%;
    }
    .action-subrow {
      padding: 5px;
    }
  }
  .flex-column {
    flex-direction: column;
  }

  .unconfirmed-icon  {
    color: $primary-accent;
    margin-left: 5px;
    vertical-align: middle;
  }
}

@container (max-width: 370px) {
  .ProfileDetailCard {
    .action-row {
      button {
        width: 100%;
      }
    }
  }
}
