@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column-na-start {
  @include flex-column;
  align-items: flex-start;
}

@mixin flex-column-start-center {
  @include flex-column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-column-between {
  @include flex-column;
  justify-content: space-between;
}

@mixin flex-row-wrap-start-start {
  @include flex-row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-row-align-center {
  @include flex-row;
  align-items: center;
}

@mixin flex-row-center-center {
  @include flex-row;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-wrap-center-center {
  @include flex-row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-between-center {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row-justify-start {
  @include flex-row;
  justify-content: flex-start;
}

@mixin flex-row-justify-center {
  @include flex-row;
  justify-content: center;
}

@mixin flex-row-justify-space-between {
  @include flex-row;
  justify-content: space-between;
}

@mixin flex-row-justify-end {
  @include flex-row;
  justify-content: flex-end;
}
