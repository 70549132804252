.PricingDataSelector {

  .suspense-content {
    height: 122px;

    .spinner {
      height: 75px;
    }
  }
  .pricing-info-prompt {
    @include flex-row;
    align-items: center;
    .required-icon {
      color: $secondary-accent;
      font-size: 8px;
      margin-left: 0;
      margin-right: 5px;

      &.invalid {
        color: $error
      }

      &.valid {
        color: $success
      }
    }
  }
  .PricingDataOption {
    cursor: pointer;

    &:hover {
      border-color: $secondary-accent;
      border: 1px solid $secondary-accent;
      box-shadow: 0 0 5px 1px $secondary-accent;

      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &.selected {
      border-color: $success;
      border: 1px solid $success;
      border-radius: 0.25rem;
      box-shadow: 0 0 7px 1px $success;
    }
  }
}
