.StartChatButton {
  position: relative;
  .rich-tooltip {
    right: 0;
    top: 110% !important;
    z-index: 1;
    margin: 0 !important;
  }

  .chat-button-tooltip {
    width: 90px;

    &.wider {
      width: 135px;
    }
  }
}