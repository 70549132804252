.Users {
    
    .pagination-container {
        min-width: 100%;
        padding-top: 30px;
    }
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
        width: 100vw;
    }
    
    .invite-icon-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem !important;
        margin-right: 27px;

        h6 {
            margin-top: 8px;
            font-weight: bold;
        }

        .invite-icon {
            font-size: 1.1rem;
        }
    }

    .user-card-wrapper {
        width: 320px !important;
    }
    
  .user-card {
    overflow: hidden;

    
    .user-card-body {
      width: 100%;
      min-height: 240px;
    }

    .user-card-avatar {
        width: 70px;
        height: 70px;
    }
    
    .user-card-body h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .user-card-body p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .user-title {
        font-weight: 900;
    }

    .Avatar {
      margin-top: 20px;
    }

    .InitialedAvatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
    }

    .user-card-footer {
      background-color: white;
      min-height: 105px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      min-width: 0;
    }

    .user-value-with-icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        min-width: 0;
    }
    
    .truncate-icon-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0;
    }

    i.user-value-icon {
        margin-left: 10px;
    }
  }

  .ActionDropdown {
    position: absolute;
    top: 10px;
    right: 10px;

    .btn-primary.dropdown-toggle {
      background-color: transparent;
      color: #005cb9;
      border: none;
    }

    .dropdown-menu {
      width: 180px !important;
    }

    .btn > i {
      font-size: 18px;
    }

    i.fa-ellipsis-v {
      margin-right: 0;
    }

    .dropdown-toggle::after {
      display: none;
    }

    li {
      cursor: pointer;
    }
  }

  .badge-container {
    position: absolute;
    top: 12px;
    left: 10px;

    .badge {
      padding: .4rem .5rem;
    }
  }
}


