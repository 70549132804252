.ConfirmLanding {
  padding-top: 30px;
  padding-bottom: 30px;
  background: $background;
  min-height: 100vh;
  max-height: 100vh;

  .col {
    @include flex-row-justify-center
  }

  .spinner-col {
    margin: 25px 0 25px 0;
  }
}
