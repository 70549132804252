.Claim {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: auto;
  background: $background;

  .bounded-container {
    border-bottom: 1px solid $grey-stroke-darker;
    padding-bottom: 0;
    margin: 0 10px 20px 10px;

    h5 {
      margin-bottom: 0;
    }
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .left-arrow {
    width: 60px;
    height: 60px;
    fill: $primary-accent;
    stroke: $primary-accent;
  }

  .ValidatedInput {
    width: 90%;
  }
}
