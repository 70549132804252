.sm-input-group {
  .input-group {
    flex-wrap: unset !important;
  }

  span.input-group-text {
    max-height: 38px;
    max-width: 38px;
  }
}
