.CustomModal {
  .modal-header {
    font-size: 1.25rem;
  }
}
.ListingArtifactFormModal {
  .Wizard {
    .wizard-header {
      .wizard-step-container {
        margin: 35px 25px 25px !important;
      }
    }
  }
}