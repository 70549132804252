.custom-tab-header {
  top: 65px;
  height: 90px;
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  padding-top: 8px;
  padding-bottom: 0px;
  color: $secondary-font2;
  i {
    padding-right: 10px;
  }

  .nav-back {
    display: flex;
    align-items: center;
    margin-left: 15px;

    .nav-back-btn {
      padding: 0;
    }

    .left-arrow {
      width: 40px;
      height: 40px;
      fill: $secondary-font2;
      stroke: $secondary-font2;
    }
  }

  .tab-headers {
    @include flex-row-align-center;

    .tab-container {
      margin-right: 35px;

      .tab-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        svg {
          stroke: $secondary-font2;
          fill: $secondary-font2;
        }

        &.active {
          color: $primary-accent;
          stroke: $primary-accent;
          fill: $primary-accent;

          svg {
            stroke: $primary-accent;
            fill: $primary-accent;
          }
        }

        &.disabled {
          color: $grey-stroke;
          stroke: $grey-stroke;
          fill: $grey-stroke;

          svg {
            stroke: $grey-stroke;
            fill: $grey-stroke;
          }
        }

        .view-innovation-header-icon {
          width: 40px;
          height: 40px;
          padding-right: 8px;
          stroke: $secondary-font2;
          fill: $secondary-font2;

          &.collaborators-icon {
            stroke-width: 3.5;
          }

          &.chat-icon {
            stroke-width: 0.5;
          }

          &.team-icon {
            stroke-width: 0.5;
          }

          &.view-icon {
            stroke-width: 0.1;
          }

          &.ignite {
            margin-right: 0;
          }

          &.active {
            stroke: $primary-accent;
            fill: $primary-accent;
          }
        }

        .chat-icon-container {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .view-innovation-header-icon {
            width: 25px;
            height: 25px;
            padding-right: 4px;
          }
        }
      }
    }
  }
}