.ListingAwardCard {
  min-height: 200px;
  padding: 0px 10px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.35)
  }

  .card-body {
    padding: 1rem 0.5rem;
  }

  .award-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 98px;
    border-bottom: 1px solid $grey-stroke-darker;

    .header-left {
      display: flex;
      justify-content: flex-start; /* Resolved compiler noise, start value has mixed support, consider using flex-start instead  */
      align-items: center;
      width: 100%;

      .artifact-details {
        width: 100%;
      }

      h5 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        transition: all .2s linear;
      }

       h5:hover {
        transform: scale(1.01);
        color: $primary-accent;
      }

    }
  }

  .date-row {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .date {
      width: 50%;
    }
  }

  .truncate-overflow {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
