.CustomInformationPopup {
  @include flex-row-justify-start;
  align-items: baseline;

  h1, h3 {
    margin-right: 15px;
  }

  .info-icon-container {
    @include flex-row-align-center;
    position: relative;


    .icon {
      @include flex-row;
      align-items: center;
    }

    .fa-info-circle {
      color: $primary-accent;
    }

    .info-panel {
      position: absolute;
      z-index: 2000;
      top: 33px;
      left: 0;
      padding: 20px 15px;
      width: 317px;
      height: fit-content;
      max-height: 300px;
      overflow-y: auto;
      background: #FFF;
      box-shadow: 0px -1px 16px -9px rgba(0,0,0,0.4);
      
      border: $strokes;

      span {
        color: $secondary-font1;
        font-weight: initial;
      }
    }
  }
} 

.TimelineCard {
  .card-body {
    .info-panel {
      top: 22px;
      left: -322px;
    }
  }
}

.ExploreInnovations {
  .hot-innovations {
    .info-panel {
      left: -183px !important;
    }
  }
}

.ExploreResource {
  .resource-grid-right {
    .info-panel {
      left: -105px;
    }
  }
}
