.admin-analytics {
  min-width: 700px;
  margin-top: 20px;
  margin-bottom: 20px;
  h2, h3 {
    color: black;
    font-weight: bold;
    margin-left: 20px;
    white-space: nowrap ;
  }
  .card {
    border-radius: .5em;
    margin-bottom: 20px;
    .stat-header {
      margin-top: 25px;
    }
  }
  #admin-analytics-line-chart-and-table-card-header {
    margin-bottom: 0;
    h2 {
      margin-top: 25px;
    }
  }
  #admin-analytics-line-chart-and-table-absent {
    margin-bottom: 20px;
  }
  #admin-analytics-technology-type-statistics-container {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    #technology-type-pie-charts-container {
      h3 {
        white-space: nowrap ;
      }
      .technology-types-card-header {
        margin-bottom: 50px;
      }
    }
    #technology-type-table-container {
      #toggle-technology-type-table {
        text-align: center;
        margin-bottom: 20px;
        #toggle-technology-type-angle-up,
        #toggle-technology-type-angle-down {
          vertical-align: middle;
          margin-left: 10px;
        }
      }
      table {
        tr {
          td {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  #admin-analytics-listing-page-views-statistics-container {
    h3 {
      font-size: 20px;
    }
    #listing-page-view-table-container {
      padding: 20px;
    }
    table {
      tr {
        td {
          font-size: 20px;
          max-width: 200px;
          h3 {
            max-width: calc(100%);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  #admin-analytics-organization-user-statistics-container {
    #organization-users-table-container {
      padding: 20px;
    }
  }

}

@media (min-width: 1200px) {
  .admin-analytics {
    padding: 0 5%;
  }
}

@media (max-width: 1000px) {
  #technology-type-pie-charts-container{
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 21px;
    }
  }
  
}

.analytics-response-rate-header {
  min-height: 100px;
  padding-top: 5px;
  .analytics-header-digits {
    color: $primary-accent;
    font-size: 3.1rem;
    font-weight: 900;
  }
  
  .anaylytics-header-text {
    width: 400px;
    
    & h3 {
      font-size: 1.5rem !important;
    }
  }
}
