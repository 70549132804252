@import "../../mixins/flex.scss";
@import "../../mixins//buttons.scss";

.Subscriptions {
  @include flex-column-start-center;

  .industry-listing-card {
    .card {
      margin: 20px;
      width: 464px;

      .industry-listing-header {
        .industry-listing-header-img {
          width: 464px;          
        }
      }

      .card-body {
        .card-text {
          padding: 8px 0 20px 0;
        }

        .card-action-footer {
          @include flex-row-between-center;

          .current-listing-counter {
            border: 1px solid $grey-stroke-darker;
            padding: 5px 8px;
          }
          
          button.btn-primary {
            @include success-button;
          }
        }
      }
    }
  }
}