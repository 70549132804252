@import '../mixins/flex';
@import '../mixins/buttons';

.RequestReset {
  @include flex-column-start-center;
  width: 100%;
  height: 100vh;
  margin: auto;
  background: $background;

  .sign-in-modal {
    width: 350px;
    text-align: center;

    .MobilizeVisionLogo {
      margin-top: 40px;
      width: 100%;
    }

    h5, p.sub-title {
      color: $secondary-font2;
    }

    input {
      margin: 10px 0;
    }

    button.btn-primary {
      width: 100%;
      margin: 20px 0;
    }

    .btn.login {
      @include success-button;
    }

    .span-btn.no-border-btn {
      @include text-button;

      &.pwd {
        @include primary-text-button;
      }

      &.acc {
        &:hover {
          color: $secondary-font1;
        }
      }
    }

    .btn.request-password-reset {
      background-color: #FFF;
      border-color: $grey-stroke;
      color: $secondary-font2;

      &:hover {
        border-color: $grey-stroke-darker;
        color: $secondary-font2;
      }
    }
  }

  .link-group {
    display: flex;
    flex-direction: column;
  }
}
