@import '../../mixins/flex.scss';

.CustomSelectedItems {
  @include flex-row-wrap-start-start;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin: 5px 0 25px 0;

  .selected-item:first-child {
    max-width: fit-content;
  }

  .selected-item {
    @include flex-row-center-center;
    @include removable-btn;

    i {
      font-size: 10px;
      margin-right: 0px;
      margin-left: 10px;
    }

    &.destroy {
      background-color: $ada-dark-red;
      color: #FFFFFF;

      span {
        text-decoration: line-through;
      }
    }

    &.destroyable {
      background-color: $primary-accent;
    }

    &.staged {
      background-color: $tertiary-accent;
    }
  }
}
