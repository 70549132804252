
.modal-body-wrapper {

  &.loading, .display-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 420px;
  }

  .spinner-container {
    min-height: 420px;

    .spinner {
      width: 80px;
    }
  }
  
  .card-header {
    border-radius: 5px;
  }

  .service-name {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-url {
    width: 100%;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .header-url a {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .service-name {
    max-width: 100%;
  }

  .service-logo {
    width: 75px !important;
  }
  
  a {
    text-decoration: none;
  } 
  
  .attachment-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .attachment-link svg {
    width: 35px;
  }

  .icon-container {
    min-width: 50px;
  }
  
  .fas.fa-pen {
    color: #005CB9;
    cursor: pointer;
  }
  
  .OptionalImage {
    min-width: 70px;
    // padding-right: 10px;
  }

  .service-logo{
    width: 100%;
  }
  
  .dates {
    border: none;
    span {
      font-weight: 800 !important;
      font-size: 1.02rem !important;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  li.nav-item {
    cursor: pointer;
  }
}