.view-notifications {
  margin: 10px;

  .card {
    padding: 20px;

    .table-responsive {
      min-height: 200px;
    }

    .chat-link {
      text-decoration: none;
      color: unset !important;
    }

    .unread-notification-indicator-column {
      min-width: 20px;
    }

    .notify-icon {
      height: 8px;
      width: 8px;
      margin-bottom: 2px;
      .st1 {
        opacity: 0.8;
        fill: #00A0DF;
        stroke: #00A0DF;
      }

      .st2 {
        opacity: 0.5;
        fill: none;
        stroke: #FFFFFF;
      }
    }

    .message-header-cell {
      width: 90%;
      vertical-align: middle;
      padding-left: 20px;
    }

    .clear-notifications-cell {
      min-width: 160px;
      text-align: center;
    }

    .clear-single-notification {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .no-notifications-message {
      text-align: center;
    }
  }
}
