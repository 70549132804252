.bulk-attachments {
  .rich-tooltip {
    z-index: 100;
    min-width: 300px;
  }

  .fa-info-circle {
    color: $primary-accent;
    margin: 0 0 0.5rem 5px;
    line-height: 1.2;
  }
}
