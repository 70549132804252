  .CustomDropdown {
  position: relative;
  margin-bottom: 30px;

  .dropdown-value,
  .dropdown-menu.isExpanded {
    @include custom-border;
    background-color: #FFF;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(128, 174, 220, 0.44);
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      outline: none;
      -webkit-appearance: none;
    }

    &.darker-strokes-border {
      border: 1px solid $grey-stroke;
    }
  }

  .dropdown-value,
  .dropdown-item {
    color: $secondary-font2;
  }

  div {
    cursor: pointer;
  }

  .dropdown-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 7px;

    &.disabled {
      pointer-events: none;
      filter: none;
      border: transparent;
      background-color: #e6e8eb;
      color: #535964;
      box-shadow: none;
    }

    .icon-container {
      @include flex-column;

      i {
        position: relative;

        &.fa-caret-up {
          bottom: -3px;
        }

        &.fa-caret-down {
          top: -3px;
        }
      }
    }
  }

  .dropdown-menu.isExpanded {
    display: inherit;
    position: absolute;
    width: 100%;
    z-index: 10;    
    padding: 20px 0;
    max-height: 200px;
    overflow-y: scroll;
  }

  .dropdown-item {
    padding: 10px 15px;

    &.select-none {
      color: $primary-accent;
      font-style: italic;
    }

    &:hover, &.isActive {
      color: $primary-accent
    }

    &:active {
      color: #FFF !important;
    }
  }
}
