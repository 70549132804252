.ServerBackedAutoComplete {
  display: flex;
  flex-direction: row;
  position: relative;

  .required-icon {
    position: absolute;
    left: -17px;
    top: 5px;
    color: #00a0df;
    font-size: 8px;
    margin-left: 5px;

    &.invalid {
      color: #e55353;
    }
  }

  .Input {
    width: 100%;
  }

  .input-container {
    height: 40px;
    .autoselect {
      padding: 8px 35px 8px 17px;
      margin: 0;
      font-size: 1rem !important;
      font-style: italic;
      border-radius: 4px;
      height: 38px;
    }

    input {
      border: 1px solid #b1b7c1;

      &.invalid {
        border: 1px solid #e55353;
      }
    }
  }

  .clear-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 7px 15px;
    background-color: #005cb9;
    color: #fff;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    p,
    h1 {
      margin: 0;
      color: #fff;
    }

    i {
      margin-right: 10px;
    }

    &:disabled,
    &.disabled {
      opacity: 0.75;
      cursor: not-allowed;

      i {
        cursor: not-allowed;
      }
    }

    &.secondary {
      background-color: #d9d9d9;
    }

    position: absolute;
    right: 2px;
    transform: translateY(35%);
    color: transparent !important;
    background: transparent !important;
    .xIcon {
      height: 10px;
      width: 10px;
      fill: #005cb9 !important;
      margin-left: 5px;
    }
  }

  .dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
    overscroll-behavior-y: contain;

    .categoryDivider {
      background: var(--backgrounds-bg-subtle, #f6f6f8);
      font-size: 1rem;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.active {
      display: block;
    }

    .dropdown-item {
      &.selected {
        background-color: #005cb9;
        color: #ffffff;
      }
    }
    .dropdown-item-loader {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .dropdown-item-spinner {
        height: 25px;
      }
    }
  }
}
