@import "../../mixins/buttons.scss";

.EditListing {
  .submit-button-group {
    .btn {
      margin-top: 20px;
      margin-bottom: 20px;

      i {
        margin-right: 5px;
      }
    }

    .btn-danger {
      background-color: $error;
      border-color: currentColor !important;
      color: white;
      font-weight: 600;

      &:hover, &:focus {
        background-color: $ada-dark-red !important;
      }

      &.disabled {
        background-color: $ada-dark-red !important;
        opacity: 0.5;
      }
    }
  }

  .files, .links {
    margin-top: 32px;
    margin-right: calc(var(--cui-gutter-x) * 0.5);
    margin-left: calc(var(--cui-gutter-x) * 0.5);
    
    .card-body {
      padding: 1.5rem 1rem;
      width: 80%;
    }
  }

  .card {
    .card-body-padding {
      padding: 0 15px;

      .card-body,
      .card-footer {
        padding: 15px 0;
      }

      .card-footer {
        background-color: #FFF;
      }
    }
  }

  .container {
    padding: 20px 0;
    display: flex;

    .col {
      width: 50%;
    }
  }

  .form-label {
    font-size: 14px;
  }

  .panel-column {
    min-width: 530px;
  }
}
