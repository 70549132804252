// If you want to override variables do it here
@import 'variables';
@import 'fonts';
@import '~@mobilizeyourtech/vision-core-react/dist/cjs/index.css';
$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

// import Fortawesome/fontawesome icon styles
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
@import 'artifact-tabs';

@import 'views/sign-in';
@import 'views/claim';
@import 'views/invite-sign-up';
@import 'views/listings';
@import 'views/request-password-reset';
@import 'views/terms-and-privacy';
@import 'views/confirm-landing';
@import 'views/setup-payment';
@import 'views/policy-page';

@import 'views/listings/add-new';
@import 'views/listings/view-listing';
@import 'views/listings/edit-listing';
@import 'views/listings/explore-listings';

@import 'views/account/account';
@import 'views/account/subscriptions';
@import 'views/account/users';
@import 'views/account/gov-users-directory';
@import 'views/account/view-notifications';

@import 'views/chat/chat-thread-view.scss';
@import 'views/chat/chat';
@import 'views/sign-up-choice';

@import 'views/gov/view-individual-gov-user';

@import 'views/admin/admin-analytics';
@import 'views/admin/organization-user-statistic';

@import 'components/pagination';

@import 'components/boundary/ErrorFallback.scss';


//Gov Listings
@import 'components/listing/connect/connect-view-listing';
@import 'components/listing/connect/connect-custom-tab-header';
@import 'components/listing/connect/connect-listing-artifact-card';
@import 'components/listing/connect/connect-listing-services-tab';
@import 'components/listing/connect/connect-listing-product-tab';
@import 'components/listing/connect/connect-listing-use-cases-tab';
@import 'components/listing/connect/connect-listing-award-card';
@import 'components/listing/connect/connect-listing-awards-tab';
@import 'components/listing/connect/connect-listing-people-tab';
@import 'components/listing/connect/connect-listing-card';


//@import 'components/custom/file-uploader';
@import 'components/custom/single-image-uploader';
@import 'components/custom/image-cropping-tool';
@import 'components/custom/bulk-attachments';


@import 'components/custom/google-suggest-address-input';
@import 'components/custom/single-user-select';
@import 'components/custom/confirmation-boundary-modal';
@import 'components/custom/legal-links';
@import 'components/custom/custom-selected-items';
@import 'components/custom/server-backed-dropdown';
@import 'components/custom/custom-dropdown';
@import 'components/custom/custom-information-popup';
@import 'components/custom/potentially-external-redirect-link';

@import 'components/custom/chat-thread-card';
@import 'components/custom/chat-inbox-panel';
@import 'components/custom/ignite-pie-chart';

@import 'components/pricing/pricing-data-selector';

@import 'components/listing/listing-awards-tab.scss';
@import 'components/listing/listing-service-product-card.scss';
@import 'components/listing/listing-award-card.scss';

@import 'components/listing/connect/connect-search.scss';
@import 'components/listing/connect/connect-advanced-search.scss';
@import 'components/listing/connect/connect-listing-detail-card.scss';

@import 'components/form/organization-details';
@import 'components/form/contracting-details';
@import 'components/form/edit-profile-form';
@import 'components/form/listing-artifact-form-modal';
@import 'components/form/fragments/social-media-accounts-form';
@import 'components/form/fragments/gov-user-extended-data-profile-form';

@import 'components/user/profile-detail-card';
@import 'components/user/change-email-form.scss';

@import 'components/inputs/search-input.scss';
@import 'components/custom/start-chat-button';
@import 'components/custom/initiate-chat-modal';

@import 'components/header/notification-bell';

@import 'components/app-sidebar-nav';