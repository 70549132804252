.ListingProductsTab,
.ListingServicesTab,
.ListingUseCasesTab {

  .create-product-button:hover, 
  .create-service-button:hover,
  .create-use-case-button:hover {
    background-color: var(--cui-light);
    color: $primary-accent;
  }

  .create-product-button:focus,
  .create-service-button:focus,
  .create-use-case-button:hover {
    background-color: var(--cui-light);
    color: $primary-accent;
  }

  .listing-products-tab-header,
  .listing-services-tab-header,
  .listing-use-cases-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;

    h1 {
      width: fit-content;
      margin-left: 1rem;
    }

    .btn {
      height: 50px;
      margin-right: 2rem;
    }
  }

  .row.listing-products,
  .row.listing-services,
  .row.listing-use-cases {
    justify-content: center;
    min-height: 200px;
    width: 100%;

    .no-products,
    .no-use-cases,
    .no-services {
      max-width: 300px;
      height: 100px;
      text-align: center;
    }
  }

  .ListingArtifactCard {
    width: 30%;
    margin: 10px;
  }

  .pagination-container {
    margin-top: 20px;
  }
}