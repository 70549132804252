.SingleImageUploader {
  position: relative;
  width: 100%;


  &.form-control {
    border: none;
    padding-left: 0 !important;

    &.validation-suspended {
      padding-right: 0
    }
    .validation-suspense {
      width: calc(1.5em + 0.95rem);
      min-width: calc(1.5em + 0.95rem);
      max-width: calc(1.5em + 0.95rem);
    }
  }

  .dropzone-container {
    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(1, 92, 185, 0.25) !important;
    }
  }

  .image-tool-container {
    align-items: center;
    width: 55px;
    margin-top: 10px;

    .clickable {
      position: relative;
      z-index: 100; // improves clickablity
    }

    .isInActive {
      cursor: not-allowed;
      // div wrapper .isInActive separated from button element 
      // since pointer-events would disable all cursors including not-allowed
      // this way, this element group is both not-allowed and no event 
      button {
        pointer-events: none;

        i {
          cursor: not-allowed;
          color: $grey-stroke;
        }
      }
    }

    .icon-button {
      background-color: #FFF !important;
      margin-right: 10px;
      padding: 5px !important;
      border: none;
      box-shadow: none;
      z-index: 100; // improves clickablity

      &:focus {
        i {
          color: $success;
        }
      }
    }

    i {
      color: rgba(0, 92, 185, 0.6);
      cursor: pointer;
      margin-right: 0 !important;
      z-index: 10;

      &:hover {
        color: $primary-accent;
      }

      &.isActive {
        color: $success
      }

      &.isValid {
        color: $success;
      }
    }

    .fa-file-upload {
      font-size: 22px;
    }

    .fa-crop {
      font-size: 25px;
    }

    .fa-image {
      font-size: 30px;
    }

    .fa-redo {
      font-size: 23px;
    }
  }

  .ImageDisplay {
    height: 200px;
    min-width: calc(100% - 51px);
    @include flex-row-justify-center;
    background: $background;
    img {
      // TBC aspect ratio
      max-width: 100%;
      width: auto;
      max-height: 100%;
    }
  }

  .FileUploader {
    height: 200px;

    .dropzone-placeholder {
      align-self: center;
      text-align: center;
      font-size: 1.1rem;
    }
  }
}
