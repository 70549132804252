.ChatInboxPanel {
  @include flex-row;
  height: 100%;

  //ChatPanel is stylized as a placeholder div
  .ChatPanel {
    border-top: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    border-right: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    border-bottom: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    background-color: $white;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    max-height: 100%;
    overflow: hidden;
  }

  .chat-thread-selector-container {
    border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    overflow-y: overlay;
    max-width: 300px;
    width: 300px;
    min-width: 300px;
    height: 100%;
    max-height: 100%;
    background-color: $white;

    .suspense-loader {
      width: 100%;
      max-height: 75px;
      .spinner {
        width: 25px;
        height: 25px;
        padding: 0;
      }
    }

    .chat-thread-item-spinner {
      height: 25px;
      width: 25px;
    }

    .chat-thread-select-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      padding: 10px 0;

      &.active {
        color: $primary-accent;
        font-weight: bold;
      }
    }

    .IntersectObservantPill {
      height: 5px;
    }

    .directory-link-container {
      a {
        text-decoration: none;
      }
    }
  }
}
