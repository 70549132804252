.ViewListing {
  min-width: 700px;

  .fixed-top {
    top: 65px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    padding-top: 8px;
    padding-bottom: 0px;

    .nav {
      cursor: pointer;
      width: 100%;
      justify-content: flex-end;
    }

    .nav-link.active {
      color: $primary-accent;
      border-bottom: 1px solid #ebedef;
      background-color: #ebedef;
    }
  }

  .tab-content {
    margin-top: 65px;
  }

  .bold-text {
    font-weight: bold;
  }

  .small-text {
    font-size: small;
  }

  .fa-pencil-alt {
    margin-top: 5px;
    color: white;
  }

  .flex-container {
    display: flex;
    justify-content: flex-end;
  }

  .view-infobar {
    @include flex-row-justify-space-between;
    margin-bottom: 15px;

    .edit-listing-btn, .visibility-action-btn {
      color: white;
      width: 40px;
      height: 40px;
    }

    .publish-action-btn {
      width: 40px;
      height: 40px;
    }

    .fa-circle {
      padding-right: 0;
      &.green {
        color: green;
      }
      &.grey {
        color: $secondary-font2;
      }
    }

    .vision-icon {
      padding-left: 0;
    }

    p.visibility-status {
      margin-left: -5px;
    }

    .visibility-action-btn svg:nth-child(2) {
      width: 50px;
    }

    .visibility-action-btn svg path:nth-child(2) {
      fill: #0D6EFD !important;
    }

    .view-infobar-status {
      @include flex-row;
      .listings-status-indicator {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 5px;
      }

      .published {
        background-color: $tertiary-accent !important;
      }
    }
  }

  .company-details {
    margin-bottom: 30px;
  }

  .listing-details {
    margin-bottom: 15px !important;
  }

  .listing-card {
    margin-bottom: 15px;
    border-radius: 0px;

    .white-bg {
      background-color: white;
    }

    .bg-primary {
      background-color: $tertiary-accent !important;
    }
    
    .artifact-link {
      font-size: 1.05rem;
      font-weight: bold;
      text-decoration: none;
    }

    .attachment-image {
      width: 50px;
      padding: 0;
      margin-left: 10px;

      svg {
        width: 100%;
      }
    }
    .Avatar {
      width: 45px;
      height: 45px;
    }
    .InitialedAvatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
    }
  }

  .listing-element-container {
    min-width: 150px;
    margin-right: 20px;

    .listing-logo {
      width: 150px;
      height: 150px;
      border-radius: 2%;
    }
  }
}
