.Account {
  margin-top: 20px;


  //TODO temp styles for card until content added
  .UserSettingsCard {
    min-height: 120px;

    .card-header {
      background-color: transparent;
    }
  }

  .ActionDropdown {
    i {
      margin-right: 0;
    }
  }

  .card-header {
    background-color: white !important;
  }

  .ProfileDetailCard {
    .card-body {
      flex: unset !important;
    }
    .card-footer {
      flex: 1
    }
  }
}
