.connect-listing-artifact-card {
  min-height: 300px;
  padding: 0px 10px;

  .card-body {
    padding: 1rem 0.5rem;
  }
  p {
    color: $secondary-font2;
  }


  .connect-listing-artifact-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 80px;

    .header-left {
      justify-content: flex-start;
      align-items: center;

      h4 {
        color: $secondary-font2;
        font-size: 19px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        transition: all .2s linear;
      }

      h4:hover {
        transform: scale(1.01);
        color: $primary-accent;
      }

      .potentially-external-redirect-link {
        display: block;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      .OptionalImage {
        border: none;
        cursor: pointer;
        transition: all .2s linear;

        display: inline-block;
      }

      .OptionalImage:hover {
        box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.35)
      }

      .artifact-details {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .artifact-logo {
      height: 70px;
      width: 70px;
      min-height: 70px;
      min-width: 70px;
      margin-right: 20px;
    }
  }

  .use-case-date-row {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .use-case-date {
      width: 50%;
    }
  }

  .truncate-overflow {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
