@import "../../mixins/buttons.scss";

.AddNew {
  padding: 15px 0;

  .files, .links {
    margin-top: 32px;
    margin-right: calc(var(--cui-gutter-x) * 0.5);
    margin-left: calc(var(--cui-gutter-x) * 0.5);
    
    .card-body {
      width: 80%;
    }
  }

  .card {
    .card-header {
      @include flex-row-justify-space-between;
      align-items: center;
      .checkout-btn {
        @include success-button;
      }

      .cancel-btn {
        @include span-btn;
        background-color: $grey-stroke-darker;
        color: #fff;

        &:hover {
          color: #fff;
          background-color: $tertiary-font !important;
        }
      }
    }
  }

  .form-label {
    font-size: 14px;
  }

  .border-left {
    border-left: 1px solid $grey-stroke;
  }

  .panel-column {
    min-width: 400px;
    &.left  { margin-left: 3px; }
    &.right { padding-right: 0px; }
  }
}
