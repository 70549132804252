.AppSidebarNav {
  .unread-message-count-badge {
    font-size: 12px !important;
    height: 2em;
    width: 2em;
    max-height: 2em;
    max-width: 2em;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    background-color: #00A0DF;
    border: 1px solid #0dcaf0;
  }
}