.InitiateChatForm {
  .form-header {
    .Avatar {
      width: 75px;
      height: 75px;
    }
    .InitialedAvatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }
  }

  textarea {
    height: 200px;
  }
}