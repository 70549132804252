.IgniteListingDetailCard {
  width: 23.5%;
  margin: 10px;
  min-height: 200px;
  padding: 10px;
  min-width: 317.34px;
  
  .organization-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .organization-logo {
      width: 65px;
      margin-right: 15px;

      .OptionalImage {
        border: none;
        object-fit: contain;
        height: 65px;
        width: 65px;
        padding: 0;

        img {
          height: 65px;
          width: 65px;
          border-radius: 5px;
        }
      }
    }

    .organization-main {
      h5 {
         margin-bottom: 2px;
         color: $primary-accent;
         font-weight: 600;
         max-width: 210px;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow: hidden;
         cursor: pointer;
         transition: all 0.2s linear;
      }

      .potentially-external-redirect-link {
        display: block;
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }
    }
  }

  .technology-types-container {
    margin: 20px 0 !important;
  }

  .truncate-overflow-container {
    &.technology-types {
      @include truncate-container-max-3;
      max-height: 71px;
      margin: 20px 0;

      .truncate-overflow {
        @include truncate-overflow-max-3;
        font-style: italic;
      }
    }

    &.statement {
      @include truncate-container-max-6;
      height: 150px;

      .truncate-overflow {
        @include truncate-overflow-max-6
      }
    }
  }
  .card-footer {
    background-color: inherit;
  }

  .statement svg {
    margin-right: 5px;
  }

  .truncate-overflow-container .statement {
    color: #6D6F74;
    & b {
      color: $primary-accent;
    }
  }
}
