.connect-listing-award-tab {
  .connect-listing-awards-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
    gap: 5px;

    h1 {
      width: fit-content;
      margin-left: 1rem;
      font-size: 20px !important;
      font-weight: bold !important;
      margin: 0;
      color: $secondary-font2;
    }
  }

  .awards-tooltip {
    width: 400px;
  }

  .rich-tooltip-container {
    & i {
      color: $primary-accent;
    }
  }

  .row.listing-awards {
    justify-content: center;
    min-height: 200px;
    width: 100%;

    .no-awards {
      max-width: 300px;
      height: 100px;
      text-align: center;
    }
  }

  .ListingAwardCard {
    margin: 10px;
    min-height: 250px;
  }

  .pagination-container {
    margin-top: 20px;
  }

  .item-col {
    min-width: 400px;
  }
}

