.GoogleSuggestAddressInput {
  position: relative;
  .required-icon {
    position: absolute;
    left: -17px;
    top: 5px;
    color: $secondary-accent;
    font-size: 8px;
    margin-left: 5px;

    &.invalid {
      color: $error
    }

    &.valid {
      color: $success
    }
  }

  .select-container {
    margin-bottom: 10px;

    .manual-raw-inputs-selector {
      cursor: pointer;
      float: right
    }
  }

  .AddressForm {
    .region-select-col {
      margin-left: 10px;
    }
  }
}
