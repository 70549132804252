.InviteSignUp,
.ClaimSignUp,
.GovUserSignup {
  padding-top: 30px;
  padding-bottom: 30px;
  background: $background;

  .gov-required-icon {
    position: relative;
    top: -5px;
    color: $secondary-accent;
    font-size: 8px;
    margin-right: 5px;

    &.tech-types {
      position: absolute;
      left: 16.5%;
      top: initial;
    }
  }

  .MultiSelectAutoComplete,
  .ServerBackedDropdown {
    width: 90%;
    .subtle {
      color: #8a93a2 !important;
    }
  }

  .bounded-container {
    border-bottom: 1px solid $grey-stroke-darker;
    padding-bottom: 0;
    margin: 0 10px 20px 10px;
    h5 {
      margin-bottom: 0;
    }
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .left-arrow {
    width: 60px;
    height: 60px;
    fill: $primary-accent;
    stroke: $primary-accent;
  }

  .autoselect,
  .ValidatedInput,
  .SingleImageUploader {
    width: 90%;
  }

  .RichTextEditor {
    width: 100%;
  }

  .RichTextEditor {
    .ql-container {
      min-height: 200px;
      max-height: 200px;
      overflow-y: scroll;
    }
  }

  .InfoIcon {
    .InfoIcon-Tooltip {
      .rich-tooltip {
        min-width: 300px;

        background: #fff;
        z-index: 10000;
      }
    }
  }
}
.delete-line-of-effort-btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--icons-icon-subtle, #535964);
  padding: 0;
  justify-content: center;
  height: 25px;
  width: 25px;

  i {
    margin: 0;
    color: var(--icons-icon-subtle, #535964);
  }
}
.delete-line-of-effort-btn:hover {
  background: transparent;
}
