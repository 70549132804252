@import '../mixins/flex';
@import '../mixins/buttons';

.SignupChoice {
  @include flex-column-start-center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: auto;
  background: $background;
  padding: 0 10%;

  .choice-header {
    position: relative;

    .nav-back {
      position: absolute;
      left: 0;
      top: 30px;

      .left-arrow {
        width: 50px;
        fill: $primary-accent;
        stroke: $primary-accent;
      }
    }
  }

  a {
    font-size: 17px;
  }

  .MobilizeVisionLogo {
    margin-top: 40px;
    width: 178px;
  }

  .toggle-content {
    color: $tertiary-font;
    i {
      margin-right: 0 !important;
    }
  }

  .why-matter-collapse {
    .toggle-content {
      justify-content: center !important;
    }
  }

  .choice-prompt {
    width: 420px;

    .toggle-content {
      justify-content: center;
      color: $tertiary-font;
    }
  }

  .choice-card {
    max-width: 300px;

    ol {
      margin-block-start: 0px;
      margin-block-end: 0px;
      padding-inline-start: 1.2rem;
    }

    .not-sure-instruction {
      color: $tertiary-font;
    }
  }

  .vision-badge-grey {
    .icon-path {
      fill: $grey-stroke-darker;
    }
  }
}
