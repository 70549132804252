@mixin ellipsis {
  /* require a div parent wrapper with width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

@mixin truncate-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: var(--word-break, 'none');
  overflow: hidden;
}

@mixin truncate {
  .truncate-overflow {
    --max-lines: 3;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
  }
  .truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
  }
  .truncate-overflow::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

h1, h2, h3, h4, h5, h6, a, p, span, text, label, link, ol, li {
  font-family: 'Karla', sans-serif;
}

h1 {
  font-weight: normal;
  font-size: 44px; 
}

h2 {
  font-weight: bold;
  font-size: 30px;
}

h3 {
  font-weight: bold;
  font-size: 22px;
}

h4 {
  font-weight: $font-weight-medium;
  font-weight: $font-weight-medium;
  font-size: 19px;
}

// Most Content Headers
h5 {
  font-weight: $font-weight-medium;
  font-weight: 400;
  font-size: 19px;
}

h6 {
  font-weight: $font-weight-medium;
  font-size: 16px;
}

span, button, label {
  font-size: 14px !important;
}

p {
  margin-bottom: 0px !important;
}
