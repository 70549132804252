.search-input {
  position: relative;
  display: flex;
  align-items: center;

  .fas {
    position: absolute;
    left: 8px;
    font-size: 1rem;
    margin: 0;
    z-index: 1;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding-left: 2rem;
    border: 1px solid #D9D9D9 !important;
  }
}
