.btn-primary {
  padding: 5px 10px !important;
  font-weight: 600;
}

@mixin success-button {
  background-color: $success !important;
  border-color: $success;

  &:hover {
    background-color: $success-hover !important;
    border-color: $success-hover;
  }
}

@mixin primary-text-button {
  color: $primary-accent;

  &:hover {
    color: $tertiary-accent;
  }
}

@mixin almost-not-a-button {
  cursor: pointer;
  color: $secondary-font2;

  &:focus-visible,
  &:hover {
    border: 1px solid $tertiary-accent;
    color: $tertiary-accent;
  }
}

@mixin text-button {
  @include almost-not-a-button;

  &:hover {
    font-weight: 600;
  }
}

@mixin span-btn {
  @include almost-not-a-button;
  background-color: #FFF;
  border: 1px solid $grey-stroke;
}

@mixin removable-btn {
  margin: 1.5px;
  padding: 5px 15px;
  color: #FFF;
  background-color: $tertiary-accent;
  
  i {
    cursor: pointer;
  }
}
