.PolicyPage {
  .card {
    max-width: 80vw;

    .card-body {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .content-container-wrapper {
    background: inherit;
    max-height: 62vh;
    overflow-x: scroll;
    background: $background;
  }

  .left-arrow {
    width: 60px;
    height: 60px;
    fill: $primary-accent;
    stroke: $primary-accent;
  }
}
