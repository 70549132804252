#gov-user-directory {
  .gov-user-directory-search {
    .SearchInput,
    .MultiSelectAutoComplete .multi-select-tools-container {
      border: 1px solid #d9d9d9;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      font-style: normal;
    }

    .search-container {
      padding: 0 10px 10px 10px;
      .search-input-container {
        margin-top: 10px;
        .SearchInputGroup.mb-3 {
          margin-bottom: 0 !important;
        }
      }
      .organization-dropdown-container,
      .technology-types-dropdown-container {
        margin-top: 10px;
      }
    }

    .hide-already-messaged-container {
      .hide-already-messaged-checkbox {
        label {
          font-weight: 700;
        }
      }
      .rich-tooltip-icon {
        color: $primary-accent;
      }
      .rich-tooltip {
        z-index: 100;
        min-width: 200px;
      }
    }
  }
  .gov-users-content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100px;

    a {
      text-decoration: none;
      color: inherit;
    }

    .user-card-wrapper {
      min-width: 317.22px;

      .GovUserDetailCard {
        min-height: 400px;

        .user-info-container {
          .chat-link {
            .chat-icon {
              color: $primary-accent;
            }
          }
        }

        .gov-user-tech-types {
          max-height: 50px;
          min-height: 50px;
          position: absolute;
          bottom: 25px;
          width: 90%;
        }

        .line-clamp {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
          color: #6d6f74;
          text-align: start;
          word-wrap: break-word;

          & b {
            color: $primary-accent;
          }
        }
      }
    }
  }
}
