.grid {
  display: flex;
  grid-column-gap: 10px;
  width: 100%;

  .grid-item {
    width: 100%;
  }
}

.view-connect-listing {
  position: relative;
  margin-top: -10px;

  .user-avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;
    object-fit: cover;
  }

  .user-avatar.initials {
    @include flex-row-align-center;
    justify-content: center;
    background-color: $grey-stroke;
    margin-right: 10px;
    font-weight: 600;
  }

  .custom-tab-header {
    margin-bottom: 10px;
  }

  .listing-content {
    padding: 20px 40px;

    .listing-header {
      display: flex;
      margin: 0 10px 20px 10px;

      .listing-logo {
        height: 100px;
        object-fit: contain;
        border-radius: 5px;
        background-color: white;
      }

      .listing-titles {
        margin-left: 20px;

        .listing-name {
          color: $primary-font;
          font-size: 2em !important;
        }

        .listing-tagline {
          color: $primary-font;
        }
      }
    }

    h2, h3, h4, h6 {
      color: $primary-accent;
    }

    h3 {
      font-size: 22px !important;
      font-weight: bold !important;
    }

    .card-header {
      background-color: #FFFFFF;
    }

    .card {
      margin: 0px 10px 20px 0px;
      .card-header {
        @include flex-row;
        align-items: flex-end;

        &.point-of-contact {
          display: flex;
          justify-content: space-between;
        }

        .contact-header-left {
          @include flex-row;
          align-items: flex-end;

          .user-avatar {
            margin-left: -10px;
            margin-right: 20px;
          }

          h3 {
            color: $tertiary-accent;
            margin-left: -10px;
            margin-right: -10px;
          }
        }

      }

      .company-details {
        justify-content: space-between;
      }

      .card-body {
        padding: 5px;

        i {
          margin-right: 10px;
          cursor: default;
        }

        .company-detail-item {
          width: 25%;
        }

        .contact-info-label {
          width: 40%;
          font-weight: bolder;
        }

        .contact-info-value {
          width: 60%;
        }

        .listing-codes {
          width: 50%;
        }

        .naics-left {
          width: fit-content;
        }

        .listing-poc-headshot {
          height: 100px;
          object-fit: contain;
        }
      }
    }

    .capability-statement {
      height: fit-content;
      min-height: 250px;
    }

    .listing-label {
      border: 1px solid $strokes;
      border-radius: 0.25rem;
      background-color: $tertiary-accent;
      color: white;
      padding: 0px 8px;
      margin: 5px 5px 0px 0px;
      width: fit-content;
    }

    .left {
      width: 50%;
    }

    .artifact-link {
      font-size: 1.05rem;
      font-weight: bold;
      text-decoration: none;
    }

    .attachment-image {
      width: 50px;
      padding: 0;
      margin-left: 10px;

      svg {
        width: 100%;
      }
    }
  }

  .potentially-external-redirect-link {
    //span {
      font-size: 1rem !important;
    //}
  }
}
