.pie-chart-container {
  height: 330px;
  .legend-item {
    color: #000000;
    display: inline-block;
    vertical-align: middle;
    max-width: 135px;
  }
  .recharts-legend-item-text {
    display: inline-block;
  }
  .recharts-sector {
    outline: none;
  }
}

@media(min-width: 1000px) {
  .pie-chart-container {
    .legend-item {
      max-width: 145px;
    }
  }
}