.ListingPeopleTab {

  .listing-people-tab-header {
    margin-top: 10px;
    margin-bottom: 20px;

    h1 {
      width: fit-content;
      margin-left: 1rem;

      font-size: 20px !important;
      font-weight: bold !important;
      margin: 0;
      color: $secondary-font2;
    }
  }

  .row.listing-people {
    justify-content: center;
    min-height: 200px;
    width: 100%;
    margin-left: 0.75rem;

    .no-people {
      max-width: 300px;
      height: 100px;
      text-align: center;
    }
  }

  .ListingPeopleCard {
    width: 30%;
    margin: 10px;
  }

  .card-body {
    height: 110px;
  }

  .pagination-container {
    margin-top: 20px;
  }

  .item-col {
    min-width: 400px;
  }
}
