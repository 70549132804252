.EditProfileForm {

  .input-group-text {
    .fab {
      margin-right: 0 !important;
    }
  }

  .gov-required-icon {
    position: relative;
    top: -5px;
    color: $secondary-accent;
    font-size: 8px;
    margin-right: 5px;

    &.tech-types {
      position: absolute;
      left: 16.5%;
      top: initial;
    }
  }

  .RichTextEditor {
    .ql-container {
      min-height: 200px;
      max-height: 200px;
      overflow-y: scroll;
    }
  }

  .InfoIcon {
    .InfoIcon-Tooltip {
      .rich-tooltip {
        min-width: 300px;

        background: #FFF;
        z-index: 10000;
      }
    }
  }
}
