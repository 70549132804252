.error-fallback {
    height: 80vh;

    .error-fallback-card {
        box-shadow: 5px 7px 20px rgba(128, 128, 128, 0.371);
        width: 750px;
        border-radius: 10px;

        img {
            width: 250px;
        }

        hr, p {
            color: grey;
        }

        .btn {
            height: 45px;
            padding: 0 .5rem !important;
            width: 160px;

            i {
                font-size: 1.1rem;
            }
        }
    }
}