@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Generic setup for the parent of truncated element
// That element is @mixin truncate-overflow
@mixin truncate-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: var(--word-break, 'none');
  overflow: hidden;
}

// Common overflow for the child element without max lines
@mixin truncate-overflow {
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  padding-right: 1rem; /* space for ellipsis */

  .truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
  }
  .truncate-overflow::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

// Apply to the elemented that needs to be truncated
@mixin truncate-container-max-2 {
  @include truncate-container;
  -webkit-line-clamp: var(--line-clamp, 2);
}

@mixin truncate-container-max-3 {
  @include truncate-container;
  -webkit-line-clamp: var(--line-clamp, 3);
}

@mixin truncate-container-max-6 {
  @include truncate-container;
  -webkit-line-clamp: var(--line-clamp, 6);
}

// Current child element to be truncated
@mixin truncate-overflow-max-2 {
  --max-lines: 2;
  @include truncate-overflow;
}

@mixin truncate-overflow-max-3 {
  --max-lines: 3;
  @include truncate-overflow;
}

@mixin truncate-overflow-max-6 {
  --max-lines: 6;
  @include truncate-overflow;
}
