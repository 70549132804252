.Chat {
  height: calc(100vh - 75px - 48px);
  max-height: calc(100vh - 75px - 48px);
  min-height: calc(100vh - 75px - 48px);
  padding-bottom: 2rem !important;

  h1 {
    margin-bottom: 0;
  }

  .view-content {
    max-height: 96%;
    flex-basis:100%;

    .view-content-card {
      min-width: 850px;
    }
  }
}
