.header-nav {
  .notification-bell-nav-link:hover, .notification-bell-nav-link:focus {
    .st0 {
      fill: var(--cui-header-hover-color, rgba(44, 56, 74, 0.95));
    }
  }
}

.NotificationBell {

  .notify-bell {

    width: 16px;
    margin-bottom: 5px;

    .st0 {
      fill: var(--cui-header-color, rgba(44, 56, 74, 0.681));
    }

    &.active {
      .st1 {
        opacity:0.8;
        fill:#00A0DF;
      }

      .st2 {
        fill:none;
        stroke:#FFFFFF;
      }
    }

    &.inactive {
      .st1 {
        display: none;
      }

      .st2 {
        display: none;
      }
    }
  }
}

.NotificationBellPopoverContent {
  min-width: 250px;
  max-width: 250px;

  h6 {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  .spinner {
    width: 65px;
  }

  .NotificationBellNotificationItem {

    &:hover {
      background-color: $primary-accent;
      color: #FFF;

      span, div, a, i {
        color: #FFF;
      }
    }

    .SimpleIconButton {
      padding: 0;

      i {
        margin: 0;
      }
    }

    a {
      color: unset;
    }
  }
}