@import '../mixins/flex.scss';

.Listings {
  .sub-header {
    padding: 0 0 24px;
    text-align: center;
    border-bottom: 1px solid $grey-stroke;
  }

  .company-card-container {
    @include flex-row-wrap-center-center;
    padding: 24px 0;

    .company-card {
      @include flex-column-between;
      width: 309px;
      height: 350px;
      padding: 15px;
      border: 1px solid $grey-stroke;
      background-color: #FFF;
      margin: 0 15px 15px 0px;

      .company-card-content {
        @include flex-column-start-center;
        --lh: 1rem;
        line-height: var(--lh);

        .truncate-overflow-container {
          p {
            color: $secondary-font1;
            font-size: 14px;
          }
        }

        .company-tagline {
          @include ellipsis;
          margin-bottom: 10px !important;
          font-size: 14px;
          width: 99%;
          text-align: center;
        }

        img {
          max-height: 100px;
          object-fit: contain;
          border-radius: 2%;
        }

        .truncate-overflow-container.tagline {
          display: -webkit-box;
          -webkit-line-clamp: var(--line-clamp, 2);
          -webkit-box-orient: vertical;
          word-break: var(--word-break, 'none');
          overflow: hidden;
          min-height: 33px;
          text-align: center;
        }

        .truncate-overflow-container.paragraph {
          @include truncate;
          height: 55px;
        }

        img.company-logo {
          width: 100px;
          margin-bottom: 15px;
        }

        .bold {
          font-weight: 600;
        }

        .margin-top {
          margin-top: 20px;
        }

        h5, p {
          margin-bottom: unset;
        }
      }

      .company-card-status {
        @include flex-row;
        justify-content: space-between;
        flex-flow: row-reverse;
        margin-bottom: 10px;

        .bg-primary {
          background-color: $tertiary-accent !important;
        }
      }

      .company-card-action {
        text-align: center;

        i {
          margin-right: 10px;
        }

        .publish-action-btn:hover {
          background-color: $tertiary-accent;
          color: #fff;
        }
      }

      .listing-status-alert {
        cursor: pointer;

        &.danger {
          color: $danger
        }

        &.light {
          color: $secondary
        }
      }
    }
  }
}
