@import '../mixins/flex';
@import '../mixins/buttons';

.SignIn {
  @include flex-column-start-center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: auto;
  background: $background;

  .sign-in-modal {
    width: 500px;
    text-align: center;

    .MobilizeVisionLogo {
      margin-top: 40px;
      width: 350px;
    }

    h5, p.sub-title {
      color: $secondary-font2;
    }

    p.sub-title {
      text-align: center;
      margin: 0 43px;
    }

    .sign-in-form {
      margin-top: 20px;
    }

    input {
      width: 75%;
      margin: 10px auto;
    }

    button {
      width: 75%;
    }

    .btn.login {
      background-color: $primary-accent;
      border-color: $grey-stroke;
    }

    .span-btn.no-border-btn {
      @include text-button;

      &.pwd {
        @include primary-text-button;
      }

      &.acc {
        &:hover {
          color: $secondary-font1;
        }
      }
    }

    .btn.create {
      background-color: #FFF;
      border-color: $grey-stroke;
      color: $secondary-font2;

      &:hover {
        background-color: $strokes !important;
        border-color: $grey-stroke-darker !important;
        color: $secondary-font2 !important;
      }

      &:focus {
        background-color: $strokes !important;
        border-color: $grey-stroke-darker !important;
        color: $secondary-font2 !important;
      }
    }

    .btn {
      padding: 5px 10px !important;
    }
  }
}
