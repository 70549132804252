#organization-user-statistics-container {
  #organization-user-statistic-table-container {
    #organization-user-statistic-table {
      text-align: center;
      margin-bottom: 20px;
    }
    table {
      tr {
        td {
          color: black;
          font-size: 20px;
          margin-bottom: 20px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}