.GovUserExtendedDataProfileForm {
  .exampleDiv {
    font-size: 14px;
  }

  .location-input {
    &.prepopulated {
      .GoogleSuggestAddressInput {
        [class$='-placeholder'] {
          color: unset;
        }
      }
    }
  }
  .gov-employee-since-year-input {
    height: fit-content;
    min-width: 200px;
  }
}
