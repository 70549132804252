.ImageCroppingTool {
  height: 200px;
  width: 100%;

  .cropping-tools-container {
    position: relative;
  }

  .cropper-container, .ImageDisplay {
    height: 200px;
    width: 100%;
    position: relative;
  }

  .ImageDisplay {
    min-width: calc(100% - 51px);
    @include flex-row-justify-center;
    background: $background;
    img {
      // TBC aspect ratio
      max-width: 100%;
      width: auto;
      max-height: 100%;
    }
  }

  .edit-indicator {
    @include flex-row-justify-center;
    color: $success;
  }

  .image-tool-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 55px;
    height: 100%;

    &.noPointerEvents {
      pointer-events: none;
    }
  }

  .cropper-tools-btn {
    position: absolute;
    right: 0px;
    bottom: -47px;
    background-color: #005CB9;
    color: #FFF;
    min-width: 90px;

    .fa-check-circle {
      font-size: 22px;
      color: #FFF;
      cursor: pointer;
    }

    i {
      color: rgba(0, 92, 185, 0.6);
      cursor: pointer;

      &:hover {
        color: $primary-accent;
      }
      &.isInActive {
        color: $grey-stroke;
        cursor: not-allowed;
      }
    }

    .fa-check-circle {
      font-size: 22px;

      &.isValid {
        color: $success;
      }

      &:hover {
        color: #FFF;
      }
    }
  }
}
