/* custom style */
@import './mixins/buttons';
@import './mixins/flex.scss';
@import './mixins/truncation.scss';

@mixin custom-border {
  border: 1px solid #ececec;
  border-radius: 5px;
  outline: none;
}

@mixin tags {
  padding: 0px 8px;
  border-radius: 3px;
  color: white;
  margin-right: 5px;
  margin-top: 5px;
}

.bold-label {
  font-weight: 900;
  color: #000;
}

.bold-label-600 {
  font-weight: 600;
  color: #000;
}

.grey-label {
  color: $unclickable-font-icon;
}

.primary-accent-label {
  color: $primary-accent;
}

.bold-color-primary-accent {
  b {
    color: $primary-accent;
  }
}

.ol-inside-modal-body {
  list-style-position: inside;
  padding-left: 30px;
  padding-bottom: 13px;
  padding-right: 30px;
  margin: 0;

  li {
    padding-bottom: 17px;
  }
}

.vision-logo-sm {
  width: 20px;
}

.vision-logo-md {
  width: 70px;
}

.btn-primary {
  background-color: $primary-accent;
  border-color: currentColor !important;

  &:hover {
    background-color: $tertiary-accent !important;
    color: #fff !important;
  }

  &:focus {
    background-color: $tertiary-accent !important;
    box-shadow: 0 0 0 0.25rem rgb(1, 92, 185, 0.25) !important;
    color: #fff !important;
  }

  &.disabled {
    background-color: $primary-accent !important;
  }
}

.ActionConfirmationModal {
  .btn-danger {
    background-color: $error;
    border: 1px solid transparent;
    color: white;

    &:hover,
    &:focus {
      background-color: $ada-dark-red !important;
    }

    &.disabled {
      background-color: $ada-dark-red !important;
      opacity: 0.5;
    }
  }

  .cancel-action-btn {
    background-color: $secondary-font2;
    border: 1px solid transparent;
    color: white;

    &:hover,
    &:focus {
      background-color: $secondary-font1 !important;
    }

    &.disabled {
      background-color: $secondary-font1 !important;
      opacity: 0.5;
    }
  }
}

.is-active-indicator {
  transform: translate(15%, 15%);
  svg {
    max-width: 20px;
    max-height: 20px;
  }
}

.form-control,
.react-date-picker__inputGroup__input {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(1, 92, 185, 0.25) !important;
  }
}

.form-select {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(1, 92, 185, 0.25) !important;
  }
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 0.25rem rgb(1, 92, 185, 0.25) !important;
}

.optional-wrapper {
  @include flex-column-na-start;

  label {
    margin-bottom: 0 !important;
  }
  span {
    font-size: 12px !important;
    color: $tertiary-font;
  }
}

.sidebar {
  background-color: #fff !important;

  .simplebar-content-wrapper {
    overflow: hidden !important;
  }

  .simplebar-track.simplebar-horizontal {
    visibility: hidden !important;
  }

  .sidebar-brand,
  .sidebar-nav,
  .sidebar-toggler {
    background-color: #fff !important;
    border-right: 1px solid $grey-stroke;
  }

  .sidebar-brand {
    @include flex-column-start-center;
    padding: 5px;
    color: $secondary-font1;

    p {
      font-size: 14px;
      padding-left: 20px;
      text-align: left;

      &.organization-name {
        font-weight: 700;
      }
    }
  }

  .sidebar-nav {
    .nav-link {
      color: $primary-accent;
      border-left: 4px solid #ffffff;

      &.focus-visible,
      &:hover,
      &.active {
        outline: 0;
        background-color: $nav-item-hover;
        border-left: 4px solid $primary-accent;
      }
    }

    .nav-group {
      .nav-group-toggle {
        background: #fff;
        color: $primary-accent;

        &:hover {
          background: $nav-item-show;
          border-left: 4px solid $primary-accent;
        }

        &::after {
          background-image: var(
            --cui-sidebar-nav-group-indicator,
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%rgb(0, 92, 185)%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
          ) !important;
        }
      }

      &.show {
        outline: 0;
        background: $nav-item-show;

        .nav-group-toggle {
          color: $primary-accent;
          background: $nav-item-show;
          border-left: 4px solid $nav-item-show;
        }

        .nav-group-items {
          .nav-link {
            color: $secondary-font1;
            border-left: 4px solid $nav-item-show;

            &:hover,
            &.active {
              color: $primary-accent;
              background-color: $nav-item-hover;
              border-left: 4px solid $primary-accent;
            }
          }
        }
      }
    }
  }

  .nav-item,
  .nav-group {
    i {
      color: $primary-accent !important;
    }

    .nav-link,
    .nav-group-toggle {
      .fa-store,
      .fa-th-list,
      .fa-id-card,
      .fa-users,
      .fa-comment,
      .fa-life-ring,
      .fa-credit-card {
        margin-left: -1.2rem !important;
      }
    }
  }

  &:focus-visible {
    outline: 0;
    background-color: $nav-item-hover;
    border-left: 4px solid $primary-accent !important;
  }

  .sidebar-toggler {
    position: relative;

    &::before {
      position: relative;
      top: 12px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f053';
      background-image: none;
      color: $secondary-font2;
    }

    &:hover {
      &::before {
        color: $primary-font;
      }
    }
  }
}

.sidebar-brand-full {
  width: 225px;
  min-height: 101px;
  margin-bottom: 7.5px;

  .brand-container {
    width: 100%;
    margin: 7px 0 47px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    img {
      width: 178px;
    }
  }

  .user-detail-container {
    margin-top: 24px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    .user-avatar {
      width: 45px;
      height: 45px;
      &.initials {
        min-height: 45px;
        min-width: 45px;
        font-size: 1.25rem;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $tertiary-font;
        color: $white;
        overflow: hidden;
        border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
      }
    }

    .user-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  img {
    width: inherit;
  }
}

.sidebar-brand-narrow {
  margin: 7px 0;
  width: 55px;

  .organization-logo-small {
    width: inherit;
  }

  .user-avatar {
    width: inherit;
    margin-top: 39px;
    margin-bottom: 4px;
    padding: 0 5px;
  }
}

.sidebar-narrow-unfoldable:hover {
  .sidebar-brand-narrow {
    .organization-logo-small {
      display: none;
    }
  }
}

.sidebar-naroow-unfoldable:not(:hover) {
  .sidebar-brand-narrow {
    width: 45px;

    .organization-logo-small {
      display: block;
      width: inherit;
    }
  }
}

.btn {
  padding: 0.19rem 0.75rem !important;

  &.success {
    @include success-button;
  }

  &.span-btn {
    @include span-btn;
  }
}

// FIXME: Remove this: https://app.clickup.com/t/86dve8wr5
i {
  margin-right: 15px;
}

.vcr-suspenseful-button,
.vcr-basic-button {
  gap: 0.5rem !important;
  i {
    margin-right: 0px;
  }
}

.alert,
.alert-dismissible {
  @include flex-row-between-center;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding: 5px 8px;
  margin: 0;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  .btn-close {
    position: unset;
    padding: 0;
    margin-left: 15px;
  }

  .bg-danger,
  .bg-sucess {
    color: #fff;
    margin-bottom: 0 !important;
  }
}

html:not([dir='rtl']) .alert-dismissible {
  padding-right: 8px;
}

html:not([dir='rtl']) .sidebar-narrow-unfoldable:hover .sidebar-toggler::before {
  top: -17px !important;
}

html:not([dir='rtl']) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 4.8rem !important;
}

@media (min-width: 768px) {
  html:not([dir='rtl']) .sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler::before,
  html:not([dir='rtl']) .sidebar-narrow .sidebar-toggler::before {
    top: -17px !important;
  }
}

.ValidatedInput {
  position: relative;

  .required-icon {
    position: absolute;
    left: -17px;
    top: 5px;
    color: $secondary-accent;
    font-size: 8px;
    margin-left: 5px;

    &.invalid {
      color: $error;
    }

    &.valid {
      color: $success;
    }
  }
}

.App {
  position: relative;

  a:not(.btn):not(.RichDropdownItem) {
    color: $primary-accent;
  }

  .span-btn {
    background-color: $primary-accent;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: $tertiary-accent;
      border: 1px solid white;
    }

    &:focus {
      background-color: $tertiary-accent !important;
      box-shadow: 0 0 0 0.25rem $primary-accent !important;
      color: #fff !important;
    }
  }

  .btn-primary {
    svg {
      margin-bottom: 3px;

      path {
        fill: #fff;
      }
    }
  }

  .btn-primary:hover {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: none;
  }

  .app-global-alert {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 9999;
  }
}

.subtle {
  font-style: italic;
  color: $secondary-font2;
}

.multiline {
  white-space: pre-wrap;
}

.user-avatar {
  border-radius: 50%;
  object-fit: cover;
}

.react-date-picker__calendar {
  z-index: 1000 !important;
}

.react-date-picker__wrapper {
  width: 100%;
  padding: 0.2813rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid #b1b7c1 !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .react-date-picker__inputGroup__input {
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      border: 1px solid #00b189;
    }
  }
}

.MultiSelectAutoComplete {
  .form-control {
    &:focus {
      box-shadow: none !important;
    }
  }

  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  span {
    font-size: 16px;
  }

  .RichDropdownItem {
    display: flex;
    flex-direction: column;
    white-space: normal;
    color: unset;

    &:active {
      color: inherit;
      background-color: inherit;
    }

    &.selected {
      color: $primary-accent;
    }

    &:not(:active):not(:hover):not(:focus-visible) {
      background-color: inherit;
    }

    &:focus-visible {
      box-shadow: unset;
      border-radius: unset;
      transition: unset;
    }
  }
}

.text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FileUploader {
  .ToggleableAlert {
    i {
      margin: 0 !important;
      color: #fff;
    }
  }
}

.RichTextEditor {
  .ql-tooltip {
    left: 35% !important;
  }
}
