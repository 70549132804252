.ConnectAdvancedSearch {
  border: unset;
  padding: unset;
  margin: 20px 0 0 0;

  .form-control {
    border: 1px solid $grey-stroke;
  }

  .flex-row {
    width: 100%;

    .listen-identifier-location {
      .listing-identifier {
        width: 50%;
      }
    }

    .CustomDropdown {
      .dropdown-menu.isExpanded {
        height: 300px;
        overflow-y: auto;
      }
    }

  }

  // ----
  .collapsible {
    border: 1px solid #ececec;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
    border: unset;
    padding: unset;
    margin-bottom: 20px;
    min-width: 400px;

    .collapsible-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      cursor: pointer;

      i {
        color: #d9d9d9;
      }

      h1 {
        font-size: 20px;
        color: $secondary-font2;
      }
    }

    .collapsible-content {
      margin-top: 20px;
    }

    .react-date-picker__wrapper {
      padding: 5.5px 5px;
    }
  }

  .SearchInputGroup {
    width: 100%;
    margin-bottom: unset !important;

    .fas {
      top: 10px;
    }
  }

  .ServerBackedDropdown {
    .dropdown {
      .dropdown-input {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}

.dropdown-value p {
  margin-left: 10px;
}

.dropdown-value .icon-container {
  margin-right: 12px;
}

.override-width {
  width: 50%;
}

.award-contract-number, .award-search-input, .listing-identifier {
  height: 44px;
}
