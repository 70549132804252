.ChangeEmailForm {
  .button-row {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 25%;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
}