.chat-thread-card {
  &:hover {
    background-color: $nav-item-hover !important;
    cursor: pointer;
  }

  &.selected {
    background-color: $nav-item-hover !important;
  }

  justify-content: center;
  align-items: center;
  background-color: $white;
  max-width: calc(100%);
  height: 105px;
  border-bottom: solid thin $gray-100;

  .badge-frame {
    height: 2em;
    width: 2em;
    min-height: 2em;
    min-width: 2em;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .badge {
    border-radius: 50%;
    overflow: hidden;
    font-size: 0.75em !important;
    padding: 0.38em 0;
    height: 2em;
    width: 2em;
    min-height: 2em;
    min-width: 2em;
    background-color: $secondary-accent;
    border: 1px solid $cyan;
  }

  .chat-thread-card-text {
    padding-left: .25rem;
    display: inline-block;
    width: 169px;
    min-width: 169px;
    max-width: 169px;
    height: 100%;

    .chat-thread-user-name {
      display: inline-block;
      color: $primary;
      font-weight: bolder;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: calc(25%)
    }

    .most-recent-chat-text {
      height: calc(75%);
      color: $gray-600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .user-avatar {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    &.initials {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      background-color: $tertiary-font;
      color: $white;
    }
  }
}

