.Pagination {
  .page-item {
    cursor: pointer;

    &.active > a {
      color: white !important;
    }
  }

  .pagination-select {
    padding: 8px;
  }

  .disabled {
    cursor: default;
  }
}
