.ListingArtifactCard {
  min-height: 200px;
  min-width: 410px;
  padding: 0px 10px;

  .card-body {
    padding: 1rem 0.5rem;
  }

  .listing-artifact-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 80px;

    .header-left {
      display: flex;
      justify-content: flex-start; /* Resolved compiler noise, start value has mixed support, consider using flex-start instead  */
      align-items: center;

      h5 {
        max-width: 220px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        transition: all .2s linear;
      }

       h5:hover {
        transform: scale(1.01);
        color: $primary-accent;
      }

      .card-link {
        display: block;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      .OptionalImage {
        border: none;
        cursor: pointer;
        transition: all .2s linear;
      }

      .OptionalImage:hover {
        box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.35)
      }
    }

    .header-right {
      display: flex;
      justify-content: flex-end; /* Resolved compiler noise, end value has mixed support, consider using flex-end instead  */
      align-items: flex-start;

      .ActionDropdown {
        border: 1px solid transparent;
        margin-right: -10px;

        a {
          cursor: pointer;
        }

        &:hover {
          color: $primary-accent !important;
          background-color: white !important;
          border: 1px solid $primary-accent;
          border-radius: 0.25rem;
        }

        &:focus {
          color: $primary-accent !important;
          background-color: white !important;
        }
        
        .btn {
          background-color: white;
          color: $secondary-font2;
          border: none;

          &:hover {
            color: $primary-accent !important;
            background-color: white !important;
          }

          &:focus {
            color: $primary-accent !important;
            background-color: white !important;
          }
        }
      }

      i {
        margin-right: 0px;
      }
    }

    .artifact-logo {
      height: 70px;
      width: 70px;
      min-height: 70px;
      min-width: 70px;
      margin-right: 20px;
    }
  }

  .use-case-date-row {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .use-case-date {
      width: 50%;
    }
  }

  .truncate-overflow {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
