.view-connect-listing {
  .card {
    @include custom-border;
    background-color: #FFF;
    transition: 0.3s;
    border-radius: 5px;
    padding: 10px;

    .card-header {
      padding: 8px;
      align-items: center;
      border-bottom: 1px solid $strokes;
    }
  }
}
