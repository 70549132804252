.chat-panel-container {
  padding: 0;

  .more-unread-notice {
    position: absolute;
    top: 40px;
    left: 45%;
    right: 45%;
    padding: 5px;
    background-color: $primary-accent;
    color: white;
    border-radius: 5px;
    text-align: center;
    min-width: 120px;
  }

  .new-message-indicator {
    position: sticky;
    left: 45%;
    right: 45%;
    bottom: 0px;
    color: white;
    z-index: 20;
    padding: 5px;
    background-color: $primary-accent;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    min-width: 120px;
  }

  .chat-panel {
    background-color: white;
    height: calc(100vh - 65px - 112px - 20px);
    min-height: 100%;
    width: 100%;
    position: relative;
    padding: 1rem;

    display: flex;
    flex-direction: column;

    .chat-header {
      min-height: 40px;
      max-height: 40px;
      border-bottom: solid thin $gray-100;
      align-content: center;
      p {
        display: inline-block;
      }
    }

    .chat-header-span {
      text-decoration: none;
      font-size: 1.25rem !important;
      color: $gray-400;
    }

    .chat-input-container {
      min-height: 70px;
      max-height: 70px;
      position: relative;
      z-index: 10;
      box-shadow: 0px -10px 10px rgba(255, 255, 255, 0.784);
    }

    .date-divider {
      @include flex-row;
      flex-flow: row;

      .boundary-value {
        flex: 1 1 auto;
        border-bottom: 1px solid $gray-100;
        margin-bottom: 10px;
      }

      .date-value {
        color: $gray-400;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8rem;
        margin-bottom: 2px;
      }
    }
    .pause-button-wrapper {
      width: 70px;
      height: 100%;

      .rich-tooltip-container {
        position: relative;
      }

      .rich-tooltip {
        position: absolute;
        width: 300px;
        left: -230px;
        height: 70px;
        bottom: 0;
        top: 50px;
        right: 0;
      }

      .chat-pause-button {
        //height: 100%;
        width: 100%;
        margin-bottom: 10px;

        i {
          padding: 0.2rem 0.5rem;
        }
      }
    }

    .message-container {
      display: flex;
      flex-direction: column-reverse;
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 0px;
      .last-message-seen-divider {
        display: flex;

        hr {
          width: 100%;
          color: $ada-dark-red;
        }

        span {
          margin: 5px;
          color: $ada-dark-red;
        }

        &.is-viewed {
          display: none;
        }
      }

      .chat-card {
        background-color: $white !important;
        .chat-card-text {
          padding-left: 1rem;
        }

        .chat-date {
          font-size: 0.8rem !important;
          margin-top: 2px;
          color: $gray-400;
        }

        .chat-user-name {
          color: $primary;
          font-weight: bolder;
        }

        .user-avatar {
          width: 50px;
          height: 50px;
          min-height: 50px;
          min-width: 50px;
          margin-left: 10px;
          border-radius: 50%;
          &.initials {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            background-color: $tertiary-font;
            color: $white;
            overflow: hidden;
            border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
          }
        }
      }

      .observer {
        height: 5px;
      }
    }
  }
  .spinner-container {
    height: 700px;
    align-items: center;
    justify-content: center;
  }

  .observer-spinner-container {
    height: 40px;
    align-items: center;
    justify-content: center;

    .message-suspense {
      height: 100%;
    }
  }

  .error-container {
    height: 100vh;

    .error-card {
      margin-top: -300px;
      width: 500px;
      height: 200px;
    }
  }

  .alert {
    position: relative;
    margin-bottom: 0.75rem;
  }

  .preactive-chat-alert {
    position: absolute;
    top: 60px;
    width: 80%;
    height: 50px;
  }

  .unclassified {
    text-align: center;
  }
}
