.wrapper {
  min-width: 1000px;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.body {
  margin-top: 75px;
}

// Defined here since *apparently* CoreUI doesn't have CSS for their own fixed header
.header-fixed {
  position: fixed;
  width: 100%;
  width: stretch; /* Resolved compiler noise, Replace fill-available to stretch, because spec had been changed  */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  z-index: 1029;
}
