.connect-listing-award-card {
  min-height: 550px;
  padding: 0px 10px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.35)
  }

  .card-body {
    padding: 1rem 0.5rem;
    color: rgb(109, 111, 116);
  }

  .connect-listing-award-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 98px;
    border-bottom: 1px solid $grey-stroke;

    .header-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .artifact-details {
        width: 100%;
      }

      h5 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
        transition: all .2s linear;
      }

      h5:hover {
        transform: scale(1.01);
        color: $primary-accent;
      }

    }
    
    h6.header-details {
      display: inline-block !important;
      color: $tertiary-font !important;
      .highlighted {
        color: $primary-accent;
        font-weight: bold !important;
        font-size: 1.01rem !important;
        font-style: italic;
      }
    }
  }

  .date-row {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .date {
      width: 50%;
    }
  }


  .truncate-overflow-container {
    &.technology-types {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 71px;
      margin: 20px 0;

      .truncate-overflow {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-style: italic;
      }
    }
  }

  .truncate-overflow {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}